<template>
  <div class="scrollable">
    <dashboard v-if="independent" :independent="independent"/>
    <div class="spacer"></div>
  </div>
</template>

<script>
import Dashboard from "@/components/Dashboard/Dashboard";
export default {
  name: "DashboardPorte",
  components: {Dashboard},
  data () {
    return {
      independent: null
    }
  },
  async created () {
    const me = this.$store.getters['auth/getMe']
    this.$store.dispatch('sidebar/changeMenu', 'Tablau de bord')
    this.independent = (await this.$store.dispatch('independent/getOneIndependentByUser', me.id))
  }
}
</script>

<style scoped>

</style>
